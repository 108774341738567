#whyreact {
    font-size: 16px;
    line-height: 30px;
    background-color: #faebd766;
}
#whyreact .course-desc li {
    line-height: 40px;
    position: relative;
    margin-left: 30px;
    list-style: none;
    font-family: 'Kurale', serif;
}
.expand {
    font-family: "FontAwesome";
    content: "\f054";
    position: absolute;
    color: #E35156;
    font-size: 14px;
    top: 15px;
    left: -20px;
    font-family: 'Kurale', serif;
}