.modal-header {
    padding: 15px;
    border-bottom: 1px solid #e5e5e5;
}
.modal-title-custom {
    margin-left: 12%;
    font-size: 28px;
    padding-bottom: 2px;
    margin: 0.938em auto 0.4em;
    line-height: 1.055555555556em;
    font-family: 'Kurale', serif;
    font-weight: 600;
}
.modal-header .btn-close {
    padding: calc(0 * .5) calc(0 * .5);
    margin: calc(-.5 * 0) calc(-.5 * 0) calc(-.5 * 0) auto;
    font-size: 10px;
    /* font-weight: 00; */
    /* color: black; */
}
.btn-primary-custom {
    color: #fff !important;
    background: -webkit-linear-gradient(top,#f5b152,#ff8c18) !important;
    border: none;
    border-radius: 3px;
    font-family: 'Kurale', serif !important;
}
.btnlg {
    width: 100%;
    margin-left: 0px;
}
.contact a {
    color: #223865 !important;
    font-family: 'Kurale', serif;
}
.quote_right{
    font-family: 'Kurale', serif;
}
.quotes{
    font-family: 'Kurale', serif;
}
.modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 110% !important;
}