.course-body {
    padding: 20px 0;
    background: #fff;
}

.courseTitle {
    color: #000000;
    font-size: 22px;
    font-weight: 600;
    margin: 0;
    font-family: 'Kurale', serif;
}

.navTitle {
    color: white !important;
    font-family: 'Kurale', serif;

}

.new_footer_area {
    /* background: #223865 !important; */
    background-image: linear-gradient(180deg,#009139,#0bacf5);
}

.footerTitle {
    color: white !important;
    font-family: 'Kurale', serif;

}

.footerPara {
    color: #ffffffc7 !important;
    font-family: 'Kurale', serif;
    text-align: justify;
}

.watchNow {
    display: flex;
    justify-content: center;
    margin-top: 30px;
    /* margin-bottom: 4%; */
    font-family: "Alata";
}

.started {
    background: -webkit-linear-gradient(top,#f5b152,#ff8c18) !important;
    border-color: -webkit-linear-gradient(top,#f5b152,#ff8c18) !important;
    color: #ffffff !important;
    font-weight: 500;
    border-radius: 3px;
    font-size: 16px !important;
    padding: 8px 15px !important;
    font-family: 'Kurale', serif;
}
hr {
    margin: 1rem 0;
    color: white !important;
    border: 0;
    border-top: var(--bs-border-width) solid;
    opacity: .25;
}




.anim-icons {
    /* position: absolute; */
    left: 0;
    top: 0;
    right: 0;
    height: 100%;
    width: 100%;
    max-width: 1170px;
    /* margin: 0 auto; */
}
.icon-paper-clip {
    width: 120px;
    height: 160px;
    background-image: url(../../Images/reactLogo.png);
}
.bounce-x {
    animation: bounce-x 10s linear infinite;
}
.anim-icons .icon {
    position: absolute;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: 100%;
}
@keyframes bounce-x {
    0% {
        transform: translateX(0)
    }

    50% {
        transform: translateX(30px)
    }

    to {
        transform: translateX(0)
    }
}
@media (max-width: 767px) {
.icon-paper-clip {
    display: none !important;
}
}