.fixednav {
    position: fixed;
    top: 0;
    width: 100%;
    overflow: hidden;
    z-index: 1000;
    font-family: 'Kurale', serif;
    font-size: 14px;
}

.oracle-banner {
    background: #0066b0 !important;
    color: #fff;
}

header .top-menu .social-left {
    float: left;
    margin: 0;
    padding: 0;
    list-style: none;
}

header .top-menu .social-left li {
    float: left;
    margin-right: 2px;
}

header .top-menu .social-left li a {
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    text-decoration: none;
}

header .top-menu .contact-rigth {
    float: right;
    margin: 0;
    padding: 0;
    list-style: none;
    line-height: 40px;
}

header .top-menu .contact-rigth li {
    float: left;
    margin-left: 20px;

}

.fixednav a {
    color: #fff;
    text-decoration: none;
}

header .top-menu .contact-rigth li a .fas {
    margin-right: 10px;

}

header .top-menu .contact-rigth li a .fas.fa-phone {
    transform: rotate(90deg);
}

@media (max-width: 767px) {
    .fixednav a {
        color: #fff;
        text-decoration: none;
        margin-right: 10px;
    }
}

.fixednav a:hover {
    color: #fff !important;
    text-decoration: none !important;
}