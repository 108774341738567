.jsBody .oracle-banner {
    background: #223865 !important;
    color: #fff;
}

.instructor-contrainer-greens {
    padding: 30px 0;
}

.instructor-contrainer-greens .box_heading_section-greens {
    margin-bottom: 25px !important;
}

.box_heading_section-greens {
    /* margin-bottom: 20px; */
    display: flex;
    align-items: center;
}

.instructor-contrainer-greens .title_box_heading_custom {
    color: #ffffff;
    font-size: 22px;
}

.instructor-contrainer-greens .contentWrapper {
    border-radius: 6px;
    box-shadow: 0 2px 12px 0 rgba(12, 7, 7, 0.15);
    background-color: #eef1f1;
    padding: 15px;
}

.batch-schedule a {
    text-decoration: none;
}

#batches .batch-schedule .batch-date {
    padding: 15px 0;
}

#batches .batch-schedule .batch-date .day-number {
    background: #182848;
    color: #fff;
}

#batches .batch-schedule .batch-date .day-number {
    font-size: 35px;
    margin-right: 10px;
    border: 1px solid #555555;
    padding: 0px 10px;
    border-radius: 15px;
    width: 65px;
    text-align: center;
    height: 65px;
    display: block;
    box-sizing: border-box;
    float: left;
    cursor: pointer;
}

#batches .batch-schedule .batch-date .date {
    font-size: 15px;
    color: #333;
    text-transform: none;
    display: block;
    font-weight: normal;
    width: calc(100% - 80px);
    height: 65px;
    float: left;
    position: relative;
    padding: 6px 0px;
    font-family: 'Kurale', serif;
}

#batches .batch-schedule .batch-date .day-text-1 {
    color: #347bb8;
    font-family: 'Kurale', serif;
}

#batches .batch-schedule .batch-date .day-text-2 {
    position: absolute;
    bottom: 4px;
    font-size: 12px;
    font-family: 'Kurale', serif;
}

.coursePrice-detail-greens .title {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 10px;
    color: #000000;
    font-family: 'Kurale', serif;
}

.coursePrice-detail-greens .course-price {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    width: 100%;
    float: left;
    margin-right: 15px;
}

.coursePrice-detail-greens .course-price .discount-price {
    font-size: 22px;
    color: #000000;
    position: relative;
    display: inline-block;
    font-weight: 400;
    opacity: 0.5;
    margin-right: 15px;
    font-family: 'Kurale', serif;
}

.rupees {
    font-size: 22px;
    color: #000000;
    position: relative;
    display: inline-block;
    font-weight: 400;
    opacity: 0.5;
    margin-right: 15px;
    font-family: 'Kurale', serif;
}

.rupees1 {
    font-size: 40px;
    font-weight: 600;
    color: #000;
    margin-right: 25px;
    display: inline-block;
    font-family: 'Kurale', serif;
}

.coursePrice-detail-greens .course-price .discount-price::before {
    content: "";
    width: 290%;
    height: 1px;
    background: #000000;
    opacity: 0.5;
    position: absolute;
    top: 100%;
    left: -3px;
}

.coursePrice-detail-greens .course-price .discount-price i {
    margin-right: 5px;
}

.coursePrice-detail-greens .course-price .final-price {
    font-size: 40px;
    font-weight: 600;
    color: #000;
    margin-right: 25px;
    display: inline-block;
    font-family: 'Kurale', serif;
}

.coursePrice-detail-greens .course-price .final-price i {
    margin-right: 5px;
    font-size: 32px;
}

.instructor-contrainer-greens .btn-custom {
    line-height: 36px;
    margin-top: 30px;
    margin-left: 15px;
    font-size: 16px !important;
    padding: 7px 25px;
    font-weight: 900;
    display: inline-block;
    text-align: center;
    cursor: pointer;
    color: #ffffff !important;
}

@media (max-width: 767px) {
    .instructor-contrainer-greens .contentWrapper {
        margin-left: 0px;
    }
}

.cancelWithText {
    position: relative
}
.rup{
    position: absolute;
}

.cancelbtn{
    width: 69px;
    padding: 21px;
    margin-top: 14px;
}