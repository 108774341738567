.margin10 {
    margin: 0 10px 20px 10px;
}
.citieslink-greens {
    padding: 0px 0 10px;
    display: inline-block;
    width: 100%;
}
.citieslink-greens ul {
    list-style: none;
    padding: 0;
    display: inline-block;
    margin: 0;
    width: 100%;
}
.citieslink-greens ul li {
    padding: 6px 12px;
    box-shadow: 2px 4px 4px rgb(41 46 51 / 69%) !important;
    background: #0071a4;
    border: none;
}
.citieslink-greens ul li {
    width: auto;
    float: left;
    /* padding: 3px 10px; */
    /* color: #333; */
    font-size: 14px;
    /* background: #fbf9f9;
    border: 1px solid #f6f4f4; */
    margin-right: 10px;
    margin-bottom: 15px;
    /* box-shadow: 2px 5px 3px #e9e9e9; */
}
.citieslink-greens ul li a, .citieslink-greens ul li a:hover, .citieslink-greens ul li a:focus, .citieslink-greens ul li a:active {
    text-decoration: none;
    color: #fff!important;
    font-family: 'Kurale', serif;
}
.trendingTitle{
    font-family: 'Kurale', serif;
    color: #000000;
    font-size: 22px;
    font-weight: 600;
}