.box_heading_section-greens {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    font-family: 'Kurale', serif;
}

.title_box_heading_custom {
    color: #000000;
    font-size: 22px;
    font-weight: 600;
    margin: 0;
    font-family: 'Kurale', serif;
}

.feature-container {
    background: #fff;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
    padding: 24px;
    height: 220px;
    margin-top: 15px;
    border-radius: 10px;
}

.feature-box {
    color: #4b6cb7;
    padding: 10px;
    border-radius: 50%;
    font-size: 22px;
    width: 55px;
    height: 55px;
    text-align: center;
    line-height: 27px;
    font-family: 'Kurale', serif;
}

.logobox img {
    width: 34px;
    filter: invert(1);
    margin-left: -5px;
}

.feature-content {
    width: 40%;
    margin: 0 10px;
}

.feature-content span {
    font-size: 14px !important;
    line-height: 24px;
    padding-left: 0px;
    font-weight: 600;
    font-family: 'Kurale', serif;
}

.feature-content h4 {
    display: flex;
    align-items: center;
    min-height: auto;
    margin: 10px 0 10px;
    color: #1e3c72;
    padding: 0px !important;
    font-family: 'Kurale', serif;
}

.feature-content p {
    font-size: 14px;
    color: #444;
    line-height: 22px;
    font-family: 'Kurale', serif;
}