.demoForm {
    padding: 30px;
    background: #ffffff;
    box-shadow: none !important;
    text-align: center;
}

.demoForm .title {
    color: #444;
    font-weight: 700;
    font-size: 22px;
    margin-bottom: 8px;
    font-family: 'Kurale', serif;
}

.demoForm .sub {
    color: #525f78;
    font-size: 14px;
    margin-bottom: 24px;
    font-family: 'Kurale', serif;
}

.curriculam-form {
    display: inline-block;
    width: 100%;
}

.curriculam-form .form-group.mob_number {
    position: relative;
}

.curriculam-form .form-group {
    float: left;
    width: 36%;
    padding-right: 15px;
}

.form-group {
    margin-bottom: 15px;
}

.curriculam-form .form-group .country_mob_prefix {
    position: absolute;
    top: 1px;
    left: 5px;
    height: 48px;
}

.country_mob_prefix {
    display: flex;
    width: 50px;
    /* height: 53px; */
    background: #fff;
}

.curriculam-form .form-group small.error {
    color: #f00;
    font-size: 13px;
}

.country-code {
    display: inline-block;
    position: relative;
    width: 46px;
    min-width: 30px;
    overflow: hidden;
    cursor: pointer;
    left: 8px;
    padding-right: 9px;
}

.country_mob_prefix .number_prefix {
    border: none;
    font-size: 16px;
    width: 100%;
    appearance: none;
    cursor: pointer;
    outline: none;
    margin-top: 10px;
}

.country_mob_prefix .prefix_code {
    font-size: 16px;
    margin-left: 7px;
    width: 54px;
    border-left: 1px solid #ddd;
    border-right: 1px solid #ddd;
    height: 100%;
    justify-content: center;
    display: flex;
    align-items: center;
    min-width: 54px;
}

.curriculam-form .form-group.mob_number input {
    padding-left: 115px;
}

.curriculam-form .form-group input {
    width: 100%;
    height: 50px;
    border: 1px solid #a3a3a3 !important;
    box-shadow: none;
    border-radius: 5px;
    padding: 15px;
}

.btn.btn-custom {
    color: #fff;
    background: -webkit-linear-gradient(top,#f5b152,#ff8c18) !important;
    border: none;
    border-radius: 3px;
    font-weight: 600;
    font-family: 'Kurale', serif;
}
.btn-enroll {
    border-radius: 4px;
    font-weight: 600;
    text-decoration: none;
    display: inline-block;
    text-align: center;
    color: #fff !important;
    width: 200px;
    height: 50px;
    font-size: 18px;
    padding: 10px;
    font-family: 'Kurale', serif;
    text-transform: uppercase;
}
@media (max-width: 767px) {
    .curriculam-form .form-group {
        float: left;
        width: 100%;
        padding-right: 15px;
    }
    
}