.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.jsn-air-balloon-phone {
  position: fixed;
  bottom: 30px;
  z-index: 99999 !important;
  float: left;
}

.request_quote_phone {
  float: left !important;
  width: 80px;
  background: none;
}

.jsn-air-balloon-phone a {
  display: block;
  text-decoration: none;
}

.jsn-air-balloon-phone img {
  width: 100%;
}


/* whatsapp */
.request_quote {
  float: right;
  width: 80px;
  background: none;
}

.jsn-air-balloon {
  position: fixed;
  right: 1px;
  bottom: 280px;
  animation: balloon 6s ease-in-out infinite;
  z-index: 99999 !important;
  float: right;
}

.jsn-air-balloon a {
  display: block;
  text-decoration: none;
}

.jsn-air-balloon img {
  width: 100%;
}

@keyframes balloon {

  0%,
  100% {
    transform: translateY(0);
  }

  30% {
    transform: translateY(-150px);
  }

  40% {
    transform: translateY(-95px);
  }

  50% {
    transform: translateY(-150px);
  }

  60% {
    transform: translateY(-95px);
  }

  70% {
    transform: translateY(-150px);
  }

  80% {
    transform: translateY(-95px);
  }

}

@media only screen and (max-width: 776px) and (min-width: 360px) {

  .jsn-air-balloon img,
  .jsn-air-balloon-phone img {
    width: 70%;
  }
}
