.starIcon .fa-stars {
    color: #ffff00;
    margin: 0px 5px;
    position: relative;
    top: -3px;
    font-size: 14px;
    font-family: 'Kurale', serif;
}

.course-banner {
    padding-bottom: 0px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 610px;
    /* padding-top: 130px; */
    overflow: hidden;
    /* background:url(../../Images/banner.png); */
        background-image: linear-gradient(180deg,#009139,#0bacf5);
}

.course-banner-title h1 {
    font-weight: 600;
    font-size: 1.75rem;
    font-family: 'Kurale', serif;
}
.course-banner-title a:hover{
    color: white !important;
    text-decoration: none !important;
}

.course-banner .course-banner-title .breadcrumbs {
    font-size: 16px;
    font-family: 'Kurale', serif;
}
.course-banner{
    color: white;
}
.breadcrumbs {
    font-weight: 400;
    font-family: 'Kurale', serif;
}

.fa-home {
    font-size: 18px;
    font-family: 'Kurale', serif;
}

.masterprgm {
    background: -webkit-linear-gradient(top,#f5b152,#ff8c18) !important;
    position: relative;
    padding: 8px 10px 8px 5px;
    margin-right: 25px;
    left: 12px;
    text-decoration: none;
    border-radius: 3px;
    color: white;
    font-size: 14px;
    font-weight: 500;
    font-family: 'Kurale', serif;
}

.masterprgm::before {
    width: 0;
    height: 0;
    border-top: 15px solid transparent;
    border-right: 12px solid #1e3c72;
    border-bottom: 17px solid transparent;
    content: "";
    position: absolute;
    left: -12px;
    top: -1px;
}

.fa-laptop {
    /* font-size: 18px; */
    margin-right: 5px
}

.course_rating {
    margin: 15px 0px;
    display: inline-block;
    padding: 5px 10px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
    background: rgba(255, 255, 255, 0.16);
    font-family: 'Kurale', serif;
}

.course-banner .course-banner-title .course-desc {
    font-size: 16px;
    padding-left: 0rem !important;
    font-family: 'Kurale', serif;
}

.course-banner .course-banner-title .course-desc li {
    display: flex;
    line-height: 30px;
}

.tick {
    font-family: "FontAwesome";
    position: relative;
    color: #F6EE23;
    font-size: 30px;
    /* top: 2px; */
    padding-right: 8px;
}

.quote-btn {
    color: #fff;
    background: -webkit-linear-gradient(top,#f5b152,#ff8c18) !important;
    border: none;
    border-radius: 3px;
    font-size: 13px;
    min-width: 221px;
    line-height: 18px;
    font-weight: 600 !important;
    font-size: 13px;
    min-width: 221px;
    display: inline-block;
    text-align: center;
    padding: 8px 0;
    text-decoration: none;
    font-family: 'Kurale', serif;
    cursor: pointer;
}
.quote-btnMain a:hover{
    text-decoration: none !important;
    color: white !important;
}
.course-banner .course-banner-title .quote-btnCustom {
    color: #ff8c18 !important;
    background: #fff !important;
    border: 1px solid #1e3c72;
    font-weight: 700 !important;
}

/* video right side */
.angVideoWidget {
    padding: 0px;
}

.course-banner .video-widget {
    /* background: #fff; */
    height: 100%;
    /* padding: 10px; */
    /* border-radius: 10px; */
}

.video_popup {
    overflow: hidden;
    position: relative;
    border-radius: 3px;
}

.video_popup a {
    display: block;
    width: 100%;
    height: auto;
    text-decoration: none;
}

.video-widget .card {
    border-radius: 0px;
}

.card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff !important;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, .125);
}

.video_popup img {
    width: 100%;
    transition: all 0.7s ease-in-out;
}

/* .m-2 {
    margin: 0.5rem !important;
} */

.play_btn {
    z-index: 99;
    position: absolute;
    top: 35%;
    width: 100%;
    height: auto;
    transition: all 0.7s ease-in-out;
    -webkit-transition: all 0.7s ease-in-out;
}

@-webkit-keyframes hvr-pulse-grow {
    to {
        -webkit-transform: scale(1.1);
        transform: scale(1.1)
    }
}

@keyframes hvr-pulse-grow {
    to {
        -webkit-transform: scale(1.1);
        transform: scale(1.1)
    }
}

.hvr-pulse-grow {
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0)
}

.hvr-pulse-grow:active,
.hvr-pulse-grow:focus,
.hvr-pulse-grow:hover {
    -webkit-animation-name: hvr-pulse-grow;
    animation-name: hvr-pulse-grow;
    -webkit-animation-duration: .3s;
    animation-duration: .3s;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-direction: alternate;
    animation-direction: alternate
}

.playbtn {
    border-radius: 50px;
    background:rgb(0 85 255);;
    color: white;
    width: 50px;
    height: 50px;
    line-height: 45px;
    text-align: center;
    cursor: pointer;
    padding-left: 4px;
    padding-top: 0px;
}

.t-list {
    border: none;
    background: #fff;
    color: #4f4f4f;
    padding: 10px 12px;
    cursor: pointer;
}

:where(.css-dev-only-do-not-override-yp8pcc).ant-collapse>.ant-collapse-item>.ant-collapse-header .ant-collapse-header-text {
    margin-inline-end: auto;
    color: #4f4f4f;
    font-weight: 700;
    font-family: 'Kurale', serif;
    font-size: 1rem;
}

:where(.css-dev-only-do-not-override-yp8pcc).ant-collapse .ant-collapse-content>.ant-collapse-content-box {
    padding: 0px 0px !important;
}

.pointer a {
    text-decoration: none;
    line-height: 30px;
    padding: 15px;
    font-family: 'Kurale', serif;

}

.pointer {
    list-style: none;

}

:where(.css-dev-only-do-not-override-yp8pcc) a {
    color: #000 !important;
    text-decoration: none;
    background-color: transparent;
    outline: none;
    cursor: pointer;
    transition: color 0.3s;
}

.listed-style {
    padding-left: 0rem !important;
}

.video-widget ul li:hover {
    cursor: pointer;
    background: #e8e8e8;
    font-weight: 600;
    font-family: 'Kurale', serif;
}

/* havequery */
.haveQuery {
    text-align: center;
    color: #333 !important;
    /* margin: 31px; */
    padding: 18px;
    width: 100%;
    background: #fff;
    margin-left: 0px !important;
    font-family: 'Kurale', serif;
}

.quotes {
    font-size: 14px;
    line-height: 2.5;
    margin: 0;
    font-weight: 600;
}

.overallquotes {
    display: flex;
    justify-content: center;
    width: 100%;
}

.quote_left {
    text-align: center;
    padding-right: 10px;
}

.quote_left .fa.fa-phone-square {
    font-size: 40px;
    color: #157ac3;
    transform: rotate(90deg);
}

.haveQuery a,
.haveQuery i {
    color: #223865 !important;
}

.quote_right {
    text-align: left;
    padding-right: 5px;
    line-height: 9px;
}

.contact {
    font-weight: bold;
    font-size: 17px;
    margin-top: 6px;
    margin-bottom: 11px;
}

.contact a {
    text-decoration: none;
}

/* cards */
.courseFeatures {
    border-top: 1px solid rgba(255, 255, 255, 70%);
    margin-top: 35px !important;
    width: 100%;
    float: none;
    font-family: 'Kurale', serif;
}

.courseFeatures li:first-child {
    border-left: 1px solid rgba(255, 255, 255, 78%);
}

.courseFeatures li {
    border-right: 1px solid rgba(255, 255, 255, 78%);
    width: 25%;
    float: left;
    text-align: center;
    list-style: none;
}

.courseFeatures h3 {
    font-size: 24px;
    line-height: 30px;
    color: #fff;
    font-weight: 700;
    padding-top: 20px;
}

.courseFeatures p {
    font-size: 14px;
    line-height: 20px;
    color: #fff;
    text-transform: capitalize;
    margin-bottom: 20px;
}

.row>* {
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    padding-right: 0rem !important;
    margin-top: var(--bs-gutter-y);
}

ul {
    padding-left: 0rem !important;
    margin-bottom: 0rem !important;
}

/* navbar */
/* .fixed_nav ul{
    margin-bottom: 0rem !important;
} */
.sticky_greens_navbx {
    background-color: #ffffff;
    min-height: 60px;
}

#fixed_navigation_bar {
    box-shadow: 0px -1px 10px rgba(45, 74, 134, 0.43);
}

.sticky_greens_navbx .nav_course {
    position: relative;
}

.fixed_nav ul {
    margin-bottom: 0px;
    font-family: 'Kurale', serif;
    font-weight: 600;
}

.fixed_nav ul li {
    display: inline-block;
    padding: 0px 15px;
    list-style: none;
}

.fixed_nav ul li a {
    text-decoration: none;
}

.fixed_nav ul li a h2 {
    font-size: 16px;
    color: #2d4a86;
    display: inline-block;
    line-height: 20px;
    padding: 15px 0;
    margin: 0;
    font-weight: 600;
}

.sticky_greens_navbx .nav_course.stick {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1030;
    background: #ffffff;
    box-shadow: 0 2px 4px #e9e9e9;
}

.stick_nav_clp {
    padding-top: 11px !important;
}

#sticky_btn_show .btn.btn-custom {
    background: -webkit-linear-gradient(top,#f5b152,#ff8c18) !important;
    color: #fff !important;
    font-weight: 500;
    font-family: 'Kurale', serif;
}

.btn-fixed {
    padding: 4px 15px !important;
}


@media (max-width: 767px) {
    .hidden-xs {
        display: none !important;
    }

    .course-banner .course-banner-title .course-desc li {
        display: flex;
        line-height: 30px;
        font-size: 14px;
    }
    .tick {
        font-family: "FontAwesome";
        position: relative;
        color: #F6EE23;
        font-size: 22px;
        top: 2px;
        padding-right: 8px;
    }
}



