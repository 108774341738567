@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Kurale&display=swap');

body {
  margin: 0;
  font-family: 'Kurale', serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Kurale', serif,
    monospace;
}
body {
  overflow-x: hidden; 
}