.insideNav {
    background-color: white;
    box-shadow: 0 8px 25px 0 rgba(0, 0, 0, 0.04);
}

.is-sticky {
    top: 0;
    left: 0;
    box-shadow: 0 10px 15px rgb(25 25 25 / 20%);
    background-color: #086ad8;
}

.is-stickyInsideNav {
    top: 0;
    left: 0;
    box-shadow: 0 10px 15px rgb(25 25 25 / 20%);
    background-color: white;
}

.mt-6 {
    margin-top: 2.5rem !important;
}

.insideNav-Link {
    padding: 10px !important;
    font-size: 14px !important;
    color: #4f4f4f !important;
    font-family: 'Kurale', serif !important;
}
a.insideNav-Link.nav-link {
    font-weight: 600;
}

@media (max-width: 767px) {
    .navbar-light .navbar-toggler {
        color: rgba(0,0,0,.5);
        border-color: rgba(0,0,0,.1);
        font-size: 0px;
    }
    .navbar-light .navbar-toggler-icon {
        font-size: 14px !important;
    }
}