.footer-sticky {
    height: 44px;
    background-color: #000000;
    text-align: center;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
}

.footer-sticky .center_bx_foot {
    margin: 0 auto;
    float: none;
}

.footer-sticky .num_card {
    text-align: left;
    font-size: 17px;
    font-weight: 600;
    color: #ffffff;
    padding-top: 10px;
    font-family: 'Kurale', serif;
}

.footer-sticky .num_card .text {
    margin-right: 10px;
}

.footer-sticky .num_card span {
    display: inline-block;
}

.footer-sticky .num_card .in_num {
    margin-right: 40px;
}

.footer-sticky .num_card a {
    color: #ffffff;
    font-family: 'Kurale', serif;
}

.in_num a {
    text-decoration: none;
}

.sendEnquiry {
    background: -webkit-linear-gradient(top, #f5b152, #ff8c18) !important;
    color: #fff !important;
    font-weight: 500;
    font-family: 'Kurale', serif;
    border-radius: 35px;
    font-size: 20px;
}