.curriculum-container {
    background: rgba(59, 130, 216, 0.11);
    padding-top: 25px;
    padding-bottom: 25px;
}

.curriculum-container .panel-default {
    box-shadow: none;
    border: 0 none;
    position: relative;
    margin-bottom: 3px;
    border-radius: 0;
}

.panel {
    background: #fff;
}

.panel-group .panel-heading {
    border-bottom: 0;
}

.panel-default>.panel-heading {
    color: #333;
    border-color: #ddd;
}

.panel-title {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 16px;
    color: inherit;
    padding-bottom: 0px !important;
    font-family: 'Kurale', serif;
}

.curriculum-container .panel-heading a {
    display: block;
    padding: 20px 60px 20px 60px;
    position: relative;
}

.curriculum-container h4 a {
    font-size: 16px;
    color: #444 !important;
    font-weight: 700;
    margin-bottom: 0;
    text-decoration: none;
}

:where(.css-dev-only-do-not-override-yp8pcc).ant-collapse-borderless>.ant-collapse-item>.ant-collapse-content {
    background-color: transparent;
    border-top: 0;
    margin-left: 40px !important;
    padding: 5px !important;
}

.accordionHeader {
    font-size: 16px;
    line-height: 24px;
    color: #4b4b4b;
    font-weight: 600;
    font-family: 'Kurale', serif;
}

.accordionPara {
    font-size: 16px;
    line-height: 24px;
    color: #4b4b4b;
    font-family: 'Kurale', serif;
}

.handsonPara {
    font-family: 'Kurale', serif;
    font-size: 16px;
    color: #4b4b4b;
    list-style-type: disc;
}

:where(.css-dev-only-do-not-override-yp8pcc).ant-collapse>.ant-collapse-item>.ant-collapse-header {
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    align-items: flex-start;
    padding: 10px 10px;
    color: rgba(0, 0, 0, 0.88);
    line-height: 1.5714285714285714;
    cursor: pointer;
    transition: all 0.3s, visibility 0s;
}