#OracleFeatures {
    background: rgba(59, 130, 216, 0.11);
    padding-top: 25px;
    padding-bottom: 25px;
    font-size: 16px;
    line-height: 30px;
    font-family: 'Kurale', serif;
}

.course-body {
    padding: 20px 0;
    background: #fff;
}

.contentList {
    margin-top: 0;
    margin-bottom: 1rem;
}
.contentList li {
    margin-left: 40px;
    margin-bottom: 8px;
    list-style: outside;
    font-size: 16px;
    margin-right: 15px;
    font-family: 'Kurale', serif;
}
.featuresTitle{
    font-size: 22px;
    font-weight: 600;
    font-family: 'Kurale', serif;
}