.courseScope {
    background: rgba(59, 130, 216, 0.11);
    padding-top: 25px;
}

.courseScope ul {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: stretch;
    align-content: stretch;
    height: auto;
    margin: 0px;
    font-family: 'Kurale', serif;
    font-size: 14px;
}

.courseScope ul li {
    display: inline-block;
    line-height: 20px;
    width: 100%;
    padding: 25px;
    background: #fff;
    margin: 10px;
    font-weight: 600;
    list-style: none;
    font-family: 'Kurale', serif;
    font-size: 14px;
}

.courseScope ul li p {
    margin-top: 15px;
    font-weight: normal;
    line-height: 22px;
    font-family: 'Kurale', serif;
    font-size: 14px;
}

.learn h2 {
    font-size: 22px;
    font-weight: 600;
    font-family: 'Kurale', serif;

}

