.promo-container {
    background: #fff;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
    padding: 24px;
}
.promo-content {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    align-content: stretch;
}
.promo-content-1 {
    color: #4b6cb7;
    border: 2px solid #4b6cb7;
    padding: 10px;
    border-radius: 50%;
    font-size: 22px;
    width: 55px;
    height: 55px;
    text-align: center;
    line-height: 27px;
    margin: 0 10px 0 0;
    font-family: 'Kurale', serif;
}
.promo-content-2 {
    width: 40%;
    margin: 0 10px;
}
.promo-content-2 h3 {
    font-size: 22px;
    font-family: 'Kurale', serif;
}
.promo-content-2 p {
    margin: 10px 0px 0px 0px;
    font-family: 'Kurale', serif;
}
.promo-content-3 {
    width: 40%;
    margin: 0 5px;
    text-align: center;
}
.promo-fee-discount {
    margin: 10px 0px 10px 0px;
    display: none;
}
.promo-enroll {
    margin: 18px 0px 0px 0px;
}
.btn.btn-custom{
    color: #fff;
    background: #E35156!important;
    border: none;
    border-radius: 3px;
}
.fa-laptops {
    font-size: 25px;
    /* margin-right: 5px; */
}