
.headerTitle {
    color: #000000;
    font-size: 22px;
    font-weight: 600;
    margin: 0;
    font-family: 'Kurale', serif;
}
#reviews {
    background: rgba(59, 130, 216, 0.11);
    padding-top: 25px;
    padding-bottom: 25px;
}

.clp-section-custom {
    margin-top: 20px;
}

.review-greens-box {
    background: #ffffff;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
    padding: 30px;
    margin-bottom: 20px;
}

.review-greens-box .review_img_greens {
    border: none !important;
}

.review-greens-box .review_img_greens {
    display: flex;
    padding-bottom: 15px;
    border-bottom: 1px solid #e1e1e1;
    min-height: 80px;
}

.review-greens-box .review_img_greens .review_alpha_img {
    margin-right: 15px;
    width: 50px;
    min-width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    font-size: 24px;
    font-weight: bold;
    font-family: 'Kurale', serif;
}

.review_alpha_img3 {
    background: #ffc85c;
}
.review_alpha_img5 {
    background: #a84269;
}

.review_alpha_img1 {
    background: #6e5c5c;
}
.review_alpha_img4 {
    background: #a84269;
}
.review_alpha_img6 {
    background: #0039b3;
}
.review_alpha_img2 {
    background: #8042a8;
}
.review_alpha_img3 span {
    color: #ffffff;
    font-size: 24px;
    font-weight: bold;
    font-family: 'Kurale', serif;
}

.review-greens-box .review_img_greens .star-rating-review i.yellow {
    color: #ffc107;
    font-size: 12px;
    font-family: 'Kurale', serif;
}
.review-greens-box .review_information_custom {
    color: #444444;
    font-size: 14px;
    line-height: 26px;
    height: 120px;
    overflow-y: auto;
    font-style: italic;
    font-family: 'Kurale', serif;
}
strong {
    font-weight: 600;
}
.customShowMoreToggle {
    background: none!important;
    border: none;
    color: #1D7CF8;
    cursor: pointer;
    font-weight: bold;
    font-size: 12px;
    text-transform: uppercase;
    line-height: 16px;
    text-decoration: none;
}
.customShowMoreToggle i {
    width: 45px;
    height: 45px;
    line-height: 46px;
    border-radius: 100%;
    background: #0071a4;
    display: block;
    text-align: center;
    margin: 0 auto;
    font-size: 24px;
    color: #fff;
    vertical-align: middle;
}
.customShowMoreToggle span {
    display: block;
    text-align: center;
    margin: 15px 0px;
    color: #333;
    font-size: 12px;
}
.fa-angle-up:before {
    content: "\f106";
}
.hide {
    display: none;
  }