.courseDetail {
    padding: 30px 0px;
}

.courseDetailTitle h2 {
    color: #000000;
    font-size: 22px;
    font-weight: 600;
    margin: 0;
    font-family: 'Kurale', serif;
}

.courseDetailTitle h3 {
    padding-bottom: 10px;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    padding-top: 10px;
    color: #222;
    font-family: 'Kurale', serif;
}




@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700;800;900&display=swap");
@import url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.11.2/css/all.css);
@import url(https://cdn.jsdelivr.net/npm/bootstrap@4.6.0/dist/css/bootstrap.min.css);

.text-primary {
    color: #5a8dee !important;
}

.card {
    box-shadow: 0px 5px 10px rgba(90, 116, 148, 0.3);
    background-color: #fff;
    border-radius: 8px;
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 0.3px;
    color: #475f7b;
    overflow: hidden;
    font-family: 'Kurale', serif;
}

.rc-accordion-toggle {
    display: flex;
    align-items: flex-start;
    cursor: pointer;
    justify-content: space-between;
    background: rgba(59, 130, 216, 0.11);
    transition: 0.3s;
}

.rc-accordion-toggle.active {
    background-color: #5a8dee;
}

.rc-accordion-toggle.active .rc-accordion-icon {
    transform: rotate(180deg);
    color: #fff;
}

.rc-accordion-card {
    border: 1px solid #ddd;
    /* border-radius: 5px; */
    margin-bottom: 10px;
    overflow: hidden;
}

.rc-accordion-card:last-child {
    margin-bottom: 0;
}

.rc-accordion-title {
    color: #4f4f4f;
    font-weight: 700;
    font-family: 'Kurale', serif;
    font-size: 1rem;
    position: relative;
    margin-bottom: 0;
    transition: 0.3s;
}

.rc-accordion-toggle.active .rc-accordion-title {
    color: #fff;
}

.rc-accordion-icon {
    position: relative;
    top: 2px;
    color: #0071a4;
    transition: 0.35s;
    font-size: 15px;
    font-family: 'Kurale', serif;
}

.rc-accordion-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 15px;
}

.rc-accordion-body p {
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    color: #727E8C;
    font-family: 'Kurale', serif;
}

.rc-collapse {
    position: relative;
    height: 0;
    overflow: hidden;
    transition: height 0.35s ease;
}

.rc-collapse.show {
    height: auto;
}

.rc-accordion-para {
    font-size: 16px;
    line-height: 24px;
    color: #4b4b4b;
    font-family: 'Kurale', serif;
}