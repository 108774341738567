.whoShouldAtn li, .mockInterview li {
    position: relative;
    font-size: 16px;
    line-height: 24px;
    padding-left: 32px;
    margin-bottom: 20px;
    font-weight: 400;
    list-style: none;
    font-family: 'Kurale', serif;
}
.whoShouldAtnTick {
    font-family: 'Kurale', serif;
    position: absolute;
    left: 0;
    top: 1px;
    color: #e65c00;
    font-size: 18px;
}
.theme-bg img:nth-child(1) {
    left: 0px;
    /* top: 480px; */
    animation: spin 4000ms linear infinite;
}
.theme-bg img {
    /* position: absolute; */
    z-index: -999;
}
.reactImg{
    margin-left: 20%;
}
@keyframes spin {
    to {transform:rotate(360deg);}
  }