#preReq {
    font-size: 16px;
    line-height: 30px;
    font-family: 'Kurale', serif;
}
.pretitle{
    font-size: 22px;
    font-weight: 600;
    font-family: 'Kurale', serif;
}
.Subtitle h3{
    font-size: 1rem;
    font-weight: 600;
    font-family: 'Kurale', serif;
}
